exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-growth-daily-movers-js": () => import("./../../../src/pages/growth-daily-movers.js" /* webpackChunkName: "component---src-pages-growth-daily-movers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plugins-js": () => import("./../../../src/pages/plugins.js" /* webpackChunkName: "component---src-pages-plugins-js" */),
  "component---src-pages-top-daily-movers-js": () => import("./../../../src/pages/top-daily-movers.js" /* webpackChunkName: "component---src-pages-top-daily-movers-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-bun-support-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/bun-support/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-bun-support-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-crm-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/crm/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-crm-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-daily-reflection-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/daily-reflection/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-daily-reflection-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-emoji-autocomplete-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/emoji-autocomplete/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-emoji-autocomplete-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-helpful-plugins-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/helpful-plugins/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-helpful-plugins-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-open-gate-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/open-gate/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-open-gate-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-scratch-note-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/scratch-note/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-scratch-note-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-trending-plugins-20240115-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/trending-plugins-20240115/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-trending-plugins-20240115-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-trending-plugins-20240122-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/trending-plugins-20240122/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-trending-plugins-20240122-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-welcome-obsidian-addict-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/welcome-obsidian-addict/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-welcome-obsidian-addict-index-mdx" */),
  "component---src-templates-plugin-author-js": () => import("./../../../src/templates/pluginAuthor.js" /* webpackChunkName: "component---src-templates-plugin-author-js" */),
  "component---src-templates-plugin-js": () => import("./../../../src/templates/plugin.js" /* webpackChunkName: "component---src-templates-plugin-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

